export default {
    nav: {
        main: {
            assets: 'Master data',
        },
        assets: {
            users: 'Users',
            globalValues: 'Settings',
            globalFiles: 'Special files',
        },
        audit: {
            accessLog: 'Access Log',
        },
        account: {
            account: 'Account',
            changelog: 'Changelog'
        },
        sales: {
            sales: 'Sales',
            customers: 'Customers',
            products: 'Articles',
            documents: 'Documents',
            quotations: 'Dashboard',
        },
        files: {
            files: 'Documents',
        }
    },
    sales:{
        overview: {
            title: 'Sales',
        },
        document: {
            title: 'Documents'
        },
        customer: {
            title: 'Customers'
        },
    },
    unsavedChangesModal: {
        header: 'Unsaved changes!',
        content: 'You have unsaved changes, do you want to continue and discard your changes?',
        discardChangesButton: 'Continue & discard changes',
    },
    duplicateModal: {
        header: 'Duplicate',
        content: 'Duplicate to customer:',
    },
    quotation:{
        field: {
            id: {
                label: '#',
            },
            createdBy: {
                label: 'Created By',
            },
            quotationContact: {
                label: 'Contact person',
                subText: 'Same as business contact'
            },
            type: {
                label: 'Type',
                value: {
                    quotation: 'Quotation',
                    contract: 'Contract',
                },
            },
            rentbuy: {
                label: 'Rent/Buy', 
            }, 
            status: {
                label: 'Status',
            },
            totalPrice: {
                label: 'Total Price',
            },
            rentalPeriod: {
                label: 'Rent Formula name',
            },
            createdAt: {
                label: 'Created At',
            },
            language: {
                label: 'Language',
                value: {
                    'nl-NL': 'NL',
                    'nl-BE': 'BE nl',
                    'fr-BE': 'BE fr',
                }
            },
            followUpDate: {
                label: 'Follow-up Date',
            },
            updatedBy: {
                label: 'Last updated by'
            },
            installationRequired: {
                label: 'Installation order required'
            },
            installationApproved: {
                label: 'Installation order approved',
                subText: 'See tab production, installation and delivery for more information.'
            },
            termsAgreed: {
                label: 'Privacy policy agreed',
                subText: 'See tab attachments for more information.',
            },
            privacyPolicyAgreed: {
                label: 'Privacy policy agreed',
                subText: 'See tab attachments for more information.',
            },
            deliveryWeek:{
                label: 'Desired delivery date',
                noTimeInterval: 'No time specified',
            },
            deliveryUnloadingLocation: {
                label: 'Loading and unloading location'
            },
            deliveryUnloadingRequired:{
                label: 'No loading / unloading data'
            },
            deliveryUnloadingIncline:{
                at: 'at',
                cmFromEntrance: 'cm from entracnce',
                label: 'Incline'
            },
            deliveryStairs: {
                label: 'Stairs',
                value: {
                    null: '-- Kies --',
                    'n/a': 'N.v.t.',
                    'upwards': 'Upwards',
                    'downwards': 'Downwards',
                },
            },
            deliveryStairsWidth: {
                label: 'Width'
            },
            deliveryStairsNumber: {
                label: 'cm and number of steps '
            },
            deliveryDoorways: {
                label: 'Doorways'
            },
            deliveryDoorwaysWidth: {
                label: 'Width'
            },
            deliveryDoorwaysHeight: {
                label: 'cm and height'
            },
            deliverySharpTurn:{
                label: 'Sharp turn'
            },
            deliverySharpTurnWidth:{
                label: 'Width'
            },
            deliverySharpTurnHeight:{
                label: 'cm and height'
            },
            deliveryThreshold: {
                label: 'Threshold higher than 10cm'
            },
            deliveryThresholdWidth: {
                label: 'Width'
            },
            deliveryThresholdHeight: {
                label: 'cm and height'
            },
            deliveryChasis: {
                label: 'Placement on chassis type'
            },
            deliveryReturningEquipment:{
                label: 'Returing equipment'
            },
            deliveryVerticalTransportProvided:{
                label: 'Customer provides vertical transport'
            },
            deliveryComments: {
                label: 'Comments'
            },
            signature: {
                label: 'Signature',
            },
            purchaseOrderNumber:{
                label: 'Purchase order number'
            },
            currentType: {
                label: 'Current Type',
                value: {
                    null: '-- Choose --',
                    'n/a': 'N/A',
                    '230': '230 V-1+N+A (=monophase)',
                    '380': '380 V-3+N+A',
                    '220': '220 V-3+N+A',
                },
            },
            electricityTypeProduction: {
                label: 'Elektra',
                value: {
                    null: '-- Choose --',
                    'n/a': 'N/A',
                    'kW_standard': 'Standard kW',
                    'kW_optional': 'Optional kW',
                },
            },
            deliveryAddress: {
                label: 'Delivery Address',
                subText: 'Same as business address',
            },
            invoiceAddress: {
                label: 'Invoice Address',
                subText: 'Same as business address',
            },
            hinges: {
                label: 'Hinges',
                value: {
                    null: '-- Choose --',
                    'n/a': 'N/A',
                    'left': 'Left',
                    'right': 'Right',
                },
            },
            profile: {
                label: 'Program to be added',
            },
            waterIn: {
                label: 'Wateraanvoer',
                subText: 'Beluchte koudwaterkraan 3/4” wartel, 2-5 bar',
            },
            waterOut: {
                label: 'Waterafvoer',
                subText: 'o PVC 50mm incl. sifon 300mm onder aansluitpunt apparaat',
            },
            waterSoftener: {
                label: 'Waterontharder c.q. -filter',
                subText: 'De klant blijft verantwoordelijk voor schade door kalk en chloride. Aanschaf van een waterontharder en/of -filter ontheft de klant niet van deze verantwoordelijkheid.',
            },
            waterSoftenerType: {
                label: 'Water Softener Type',
                value: {
                    null: '-- Choose --',
                    'n/a': 'N/A',
                    'existing': 'Already present',
                    'supplied': 'Delivery Levens Middleby',
                    'own risk': 'The customer does not purchase any recommended water softener or filter and is aware of the (possible) consequences of this.',
                },
            },
            electricityInstallation: {
                label: 'Electricity Instalation',
            },
            electricityTypeInstallation: {
                label: 'Electricity Type Installation',
                value: {
                    null: '-- Choose --',
                    'n/a': 'N/A',
                    '380 wd 16': '380 V-3+N+A CEE WD 16',
                    '380 wd 32': '380 V-3+N+A CEE WD 32',
                    '380 wd 63': '380 V-3+N+A CEE WD 63',
                    '380 wd 125': '380 V-3+N+A CEE WD 125',
                },
            },
            electricitySeparateGroup: {
                subText: 'Afzekeren voor I=P/660, apart elektrische groep',
            },
            gasInA3: {
                label: 'Gas Supply',
                subText: 'Afvoerklassen A3, aansluitdruk 28-25 mBar',
            },
            gasSupplyType: {
                label: 'Gas Supply Type',
                value: {
                    null: '-- Choose --',
                    'n/a': 'N/A',
                    '05 NEN': '0.5 according to NEN 3258',
                    'W6pg': 'W6pg natural gas',
                    '12L': 'Device category 12L',
                },
            },
            gasAndElectricity: {
                label: 'Gas en Elektra',
                subText: '230 V + aarde, 50/60 Hertz',
            },
            airOut: {
                label: 'Luchtafvoer',
                subText: 'o 200mm',
            },
            level: {
                label: 'Waterpas',
                subText: 'Vloer en muren',
            },
            space: {
                label: 'Ruimte',
                subText: 'Min, 30 cm aan rechterzijde t.b.v. service en ontkalking',
            },
            remarks: {
                label: 'Opmerkingen/overige producten',
            },
            otherSignees: {
                label: 'Other partners / directors',
                add: {
                    label: 'Add other partners / directors'
                }
            },
            paymentTerms: {
                label: 'Payment Terms',
                value: {
                    null: '-- Choose --',
                    'direct debit': 'Payment in advance by direct debit',
                    'before delivery': 'Payment 100% before delivery',
                    '8 days after': 'Payment 8 days after delivery',
                    '10-50-35-5': 'Payment 10% on order, 50% on ordering the products, 35% 1 week before delivery and 5% within 8 days after delivery',
                    'within 30 days': 'Payment within 30 days after the invoice date',
                    'rental': 'Rental agreement',
                    'custom': 'With the conditions entered below',
                },
            },
            paymentTermsDetails: {
                label: 'Payment Terms Details',
            },
            addBtw: {
                label: 'Add BTW',
                subText: 'Add BTW percentage to the total amount',
            },
            deleted: {
                label: 'Deleted',
            },
        },
        status: {
            draft: {
                label: 'Draft'
            },
            sent: {
                label: 'Sent'
            },
            draft_contract: {
                label: 'Draft'
            },
            sent_contract: {
                label: 'Sent'
            },
            approved:{
                label: 'Approved'
            }
        },
        option: {
            attachments: {
                label: 'Attachments',
                add: {
                    label: 'Add attachment'
                },
            },
            approve:{
                label: 'Approve',
            },
            duplicate:{
                label: 'Duplicate',
            },
            remove:{
                label: 'Remove',
            },
            downloadQuote:{
                label: 'Download quote',
            },
            downloadContract:{
                label: 'Download contract',
            },
            downloadPurchaseContract:{
                label: 'Download purchase contract',
            },
            downloadRentalContract:{
                label: 'Download  rental contract'
            },
            downloadInstallationReport:{
                label: 'Download installation report',
            },
            downloadProrentInstallationReport:{
                label: 'Download Prorent Installatie Report',
            },
        },
        editModal: {
            title: 'Quotation',
            searchProduct: 'Search for a product and add it ...',
            sendQuotationButton: 'Send quotation',
            sendContractButton: 'Send contract',
            resendQuotationButton: 'Resend quotation',
            resendContractButton: 'Resend contract',
        },
        tab:{
           articles:{
             label: 'Articles'
           },
           details: {
             label: 'Details'
           },
           email: {
               label: 'Email'
           },
           production: {
               label: 'Production'
           },
           installation: {
               label: 'Installation'
           },
           delivery: {
               label: 'Delivery'
           },
           agreement: {
               label: 'Agreement'
           },
           attachments: {
               label: 'Documents'
           },
        },
    },
    quotationProduct:{
        field: {
            rentDuration: {
                label: 'Duration',
                value: {
                    buy: 'Buy',
                    rent: 'Rent',
                    durationInMonths: '{{duration}} months',
                },
            },
            monthlyRent: {
                label: 'Monthly rent'
            },
            deposit: {
                label: 'Deposit'
            },
        },
    },
    email: {
        field: {
            recipients: {
                label: 'To'
            },
            cc: {
                label: 'CC'
            },
            subject: {
                label: 'Subject'
            },
            content: {
                label: 'Content'
            }
        },
    },
    document: {
        field: {
            name: {
                label: 'Name'
            },
            sender: {
                label: 'Sender'
            },
            category: {
                label: 'Category',
                value: {
                    'privacy policy': 'Privacy Policy',
                    'purchase agreement': 'Purchase Agreement',
                    'purchase terms': 'Purchase Terms',
                    'rental agreement': 'Rental Agreement',
                    'rental terms': 'Rental Terms',
                    'installation report': 'Installation Report',
                    'other': 'Other',
                    'quotation': 'Quotation',
                    'contract': 'Contract',
                },
            },
            language: {
                label: 'Language',
                value: {
                    'all': 'All',
                    'nl-NL': 'NL',
                    'nl-BE': 'BE nl',
                    'fr-BE': 'BE fr',
                },
            },
            file: {
                label: 'File'
            },
        },
        overview: {
            addButton: 'Create new document'
        },
        create: {
            title: 'Add Document'
        },
        edit: {
            title: 'Edit Document'
        }
    },
    user: {
        overview: {
            title: 'Users',
            addButton: 'Add User',
        },
        create: {
            title: 'Create User',
        },
        edit: {
            title: 'Edit User',
        },
        field: {
            fullName: {label: 'Name'},
            firstName: {label: 'First Name'},
            lastName: {label: 'Last Name'},
            phone: {label: 'Phone Number'}, 
            email: {label: 'Email'},
            password: {label: 'Password'},
            dateJoined: {label: 'Added At'},
            groups: {
                label: 'Groups',
                value: {
                    superuser: 'Superuser',
                    admin: 'Admin',
                    sales_agent: 'Sales Agent',
                    manager: 'Manager',
                },
                empty: 'No groups assigned',
            },
            language: {
                label: "Language"
            },
            isActive: {
                label: 'Users',
                options: {
                    all: 'All',
                    yes: 'Active',
                    no: 'Not Active',
                }
            },
        },
        login: {
            title: 'Login',
            forgotPasswordLink: 'Forgot password?',
            errors: {
                invalidCredentials: 'Username or password do not match',
                unknown: 'Unknown error, status code: {{status}}',
            },
            loginButton: 'Log In',
        },
        passwordForgot: {
            title: 'Forgot password',
            requestButton: 'Send Email',
            requestedEmailText:
                'An email with a reset code has been sent to this email address.',
            loggedInError: 'You are logged in. Password forgot only works when you are not logged in.',
        },
        resetPassword: {
            title: 'Reset password',
            resetButton: 'Reset',
            saveSuccess: 'Password is successfully reset.',
            loggedInError: 'You are logged in. Password reset only works when you are not logged in.',
        },
        changePassword: {
            title: 'Change Password',
            field: {
                passwordOld: {
                    label: 'Current password',
                },
                passwordNew: {
                    label: 'New password',
                },
            },
            saveButton: 'Save',
        },
        account: {
            title: 'Account Details',
            logoutButton: 'Log Out',
            info: {
                title: 'Account Info',
            },
            password: {
                title: 'Change Password',
            },
            logout: {
                title: 'Log Out',
                confirm: 'Are you sure you want to logout?'
            },
        },
    },
    address: {
        field: {
            street: {
                label: 'Street'
            },
            number: {
                label: 'Number'
            },
            city: {
                label: 'City'
            },
            postcode: {
                label: 'Post code'
            },
            fullAddress: {
                label: 'Address'
            },
            addressLine: {
                label: 'Address (street + number)'
            }, 
            companyName: {
                label: 'Remarks'
            }
        },
    },
    contactPerson: {
        field: {
            initial: {
                label: 'Initials'
            },
            firstName: {
                label: 'First name'
            },
            lastName: {
                label: 'Last name'
            },
            middleName: {
                label: "Middle Name",
            },
            fullname: {
                label: 'Contact Person'
            },
            birthDate: {
                label: 'Date of birth'
            },
            passport: {
                label: 'Passport number',
                note: 'Mandatory if nationality differs from country of agreement',
            },
            nationality: {
                label: 'Nationality'
            },
            function: {
                label: 'Position'
            },
            phone: {
                label: 'Phone number'
            },
            mobile: {
                label: 'Mobile number'
            },
            email: {
                label: 'E-mail address'
            },
            newsletter: {
                label: 'Newsletter'
            },
            title:{
                label: 'Title',
                mr: {
                    label: 'Mr.',
                },
                ms: {
                    label: 'Ms.'
                }
            },
            privateAddress: 'Private Address',
            street: {
                label: 'Street'
            },
            number: {
                label: 'Number'
            },
            city: {
                label: 'City'
            },
            postcode: {
                label: 'Post code'
            },
            fullAddress: {
                label: 'Address'
            },
            addressLine: {
                label: 'Address (street + number)'
            }, 
        },
    },
    customer: {
        overview: {
            title: 'Customers',
            addButton: 'Add customer',
            import: {
                csv: 'Import from CSV',
            }
        },
        info: {
            title: 'Customer info'
        },
        field: {
            relationNumber: {
                label: 'Relation number'
            },
            followUpDate: {
                label: 'Follow-up Date',
            },
            status: {
                label: 'Status'
            },
            name: {
                label: 'Company name'
            },
            establishedSince: {
                label: 'Established Since'
            },
            companyType: {
                label: 'Company Type'
            },
            mainActivity: {
                label: 'Main activity',
                value: {
                    'dealer': 'Dealer',
                    'retail': 'Retail',
                    'foodservice': 'Food service',
                    'fastservice': 'Fast service',
                    'facility': 'Facility',
                    'leisure': 'Leisure',
                    'petrol': 'Petrol',
                    'bakery': 'Bakery',
                    'industry': 'Industry',
                    'delivery': 'Delivery',
                    'to go': 'To go',
                    'other': 'Other',
                    'intercompany': 'InterCompany',
                    'unknown': 'Unknown',
                    'reseller': 'Reseller',
                    'to be determined': 'To be determined'
                },
            },
            mainActivitySubcategory:{
                label: 'Main activity subcategory',
                value: {
                    'supermarket': 'Supermarket',
                    'produce': 'Produce',
                    'bakery': 'Bakery',
                    'butchery': 'Butchery',
                    'fish': 'Fish',
                    'hotel': 'Hotel',
                    'restaurant': 'Restaurant',
                    'eatery': 'Eatery',
                    'lunchroom': 'Lunchroom',
                    'pizza': 'Pizza',
                    'burger':  'Burger',
                    'cafeteria':  'Cafeteria',
                    'chicken': 'Chicken',
                    'government': 'Government',
                    'catering': 'Catering',
                    'company restaurant': 'Company restaurant',
                    'care': 'Healthcare',
                    'education': 'Education',
                    'holiday park': 'Holiday park/camping',
                    'theme park': 'Theme Park',
                    'party center': 'Party Center',
                    'sport': 'Sport',
                    'other': 'Other',
                    'warehouse': 'Warehouse',
                    'to be determined': 'To be determined',
                    'retail': 'Retail',
                    'coffe': 'Coffee',
                    'sandwich': 'Sandwich',
                    'petrol': 'Gasoline',
                    'industry': 'Industry',
                    'to go': 'To go',   
                    'consultant': 'Consultant',
                    'intercompany': 'InterCompany',
                    'delivery': 'Delivery',
                    'wholesale': 'Wholesale',
                    'reseller': 'Reseller',
                    'major kitchen dealer': 'Major kitchen dealer',
                    'webshop': 'Webshop'
                },
            },
            initialContact: {
                label: 'Initial contact',
                value: {
                    'advertisement': 'Advertisement',
                    'exchange': 'Exchange',
                    'website contactform': 'Website contact form',
                    'dealer': 'Dealer',
                    'demo day': 'Demo Day',
                    'call': 'Call',
                    'cold purchase': 'Cold purchase',
                    'mailing': 'Mailing',
                    'network': 'Networking',
                    'service': 'Service',
                    'social media': 'Social media',
                    'telemarketing': 'Telemarketing',
                    'online lead': 'Online lead',
                    'other': 'Other',
                    'event': 'Event',
                    'import': 'Import',
                    'webshop': 'Webshop',
                    'innovation days 06-22': 'Innovation days 06-22',
                    'cross selling': "Cross selling",
                    'letter': 'Letter',
                    'headquarters': 'Headquarters',
                    'coupon': 'Coupon',
                    'email': 'Email',
                    'fair': 'Fair',
                    'reseller': 'Reseller'
                },
            },
            bicCode: {
                label: 'BIC code'
            },
            kvkCode: {
                label: 'KvK / btw number'
            },
            iban: {
                label: 'IBAN'
            },
            language: {
                label: 'Language'
            },
            contactPerson: {
                label: 'Contact person'
            },
            address: {
                label: 'Address'
            },
            lastUpdatedBy: {
                label: 'Last updated by'
            },
            importModal:{
                importedStatusLabel: '{{number}} files have been imported from {{files}}.',
                updatedStatusLabel: '{{number}} files have been imported. You tried to overwrite {{updatedNumber}} existing customers'
            }
        },
    },
    product: {
        overview: {
            title: 'Products',
            addButton: 'Create product',
            export: {
                csv: 'Export to CSV'
            },
            import: {
                csv: 'Import from CSV'
            },
        },
        create: {
            title: 'Create product'
        },
        edit: {
            title: 'Edit product'
        },
        field: {
            id: {
                label: 'ID'
            },
            articleCode: {
                label: 'Article Code'
            },
            brand: {
                label: 'Brand'
            },
            name: {
                label: 'Article'
            },
            description: {
                label: 'Description'
            },
            buyPrice: {
                label: 'Buy price (excl)'
            },
            rentPrice: {
                label: 'Rent price / maand (excl)'
            },
            deposit: {
                label: 'Deposit (excl)'
            },
            needsSoftener: {
                label: 'Water softner needed'
            }
        },
    },
    myFilter: {
        custom: 'Custom',
        action: {
            unsetDefault: 'Unset Default',
            setDefault: 'Set Default',
            setCurrent: 'Save Current Filters',
            delete: 'Delete',
        },
    },
    globalValue: {
        edit: {
            title: 'Edit value of global setting',
        },
        overview: {
            title: 'Global settings',
        },
        field: {
            key: {
                label: 'Setting name',
            },
            value: {
                label: 'Value',
            },
            writable: {
                label: 'Writable?',
            },
            readable: {
                label: 'Readable?',
            },
        },
    },
    globalFile: {
        edit: {
            title: 'Edit special file',
        },
        overview: {
            title: 'Special files',
        },
        field: {
            key: {
                label: 'Name for file',
            },
            value: {
                label: 'File',
            },
            writable: {
                label: 'Writable?',
            },
            readable: {
                label: 'Readable?',
            },
        },
    },
    accessLogEntry: {
        overview: {
            title: 'Audit log',
        },
        field: {
            originalUser: {
                label: 'Original user',
            },
            masqueradingAsUser: {
                label: 'Masquerading as user',
            },
            ipAddress: {
                label: 'IP address',
            },
            method: {
                label: 'HTTP method',
            },
            endpoint: {
                label: 'Endpoint',
            },
            statusCode: {
                label: 'Status code',
            },
            additionalInfo: {
                label: 'Extra information',
            },
            requestTime: {
                label: 'Time of access',
            },
        },
    },
    tooltips: {
        masquerade: 'Masquerade',
        edit: 'Edit',
        delete: 'Delete',
        view: 'View',
        restore:'Restore',
    },
    form: {
        repeat: 'Repeat',
        fileType: {
            pdf: 'PDF',
            image: 'IMG',
            xls: 'XLS',
            any: 'Other',
            none: 'No file uploaded yet',
        },
        required: '* Required',
        time: {
            from: 'from',
            to: 'to',
            hour: 'hour',
        },
        bool: {
            true: 'Yes',
            false: 'No'
        },
        addButton: 'Add',
        cancelButton: 'Cancel',
        saveButton: 'Save',
        submitButton: 'Submit',
        applyButton: 'Duplicate',
        multiPick: {
            searchPlaceholder: 'Select…',
            selectedText: '$1 of $2 selected',
            noneSelectedText: 'None selected',
            selectAllButton: 'All',
            selectNoneButton: 'None',
        },
        notifications: {
            saveSuccess: 'Saved successfully',
            deleteSuccess: 'Deleted successfully',
            saveError: 'Error with saving ({{status}})',
            saveValError: 'Not all information is filled in correctly',
            saveAuthError: 'Invalid credentials',
            newAppVersion:
                'New version available, click to reload page as soon as possible',
        },
        startDate: 'Start Date',
        endDate: 'End Date',
        deleteConfirmation: 'Are you sure you want to delete this entity?',
        restoreConfirmation: 'Are you sure you want to restore this entity?',
        pdfError: 'It looks like this is not a valid PDF file.',
        pdfLoading: 'Loading PDF file...',
    },
    crash: {
        heading: 'Application crashed',
        subHeading1: "We’re very sorry, but the application has crashed.",
        subHeading2: 'Our team has been notified.',
        reload: 'Reload page'
    },
    daycy: {
        week: {
            label: 'Wk',
            number: '{{weekNumber}}',
        },
        weekDay: {
            monday: 'Mo',
            tuesday: 'Tu',
            wednesday: 'We',
            thursday: 'Th',
            friday: 'Fr',
            saturday: 'Sa',
            sunday: 'Su',
        },
        month: {
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December',
        },
    },
    locale: {
        NL_NL: {
            label: 'NL',
        },
        EN_GB: {
            label: 'EN GB',
        },
        BE_FR: {
            label: 'BE FR',
        },
        BE_NL:{
            label: 'BE NL'
        },
    },
    pageNotFoundMessage: 'Page not found',
    brandingMessage: 'Powered by Code Yellow',
};
