export default {
    nav: {
        main: {
            assets: 'Stamgegevens',
        },
        assets: {
            users: 'Gebruikers',
            globalValues: 'Instellingen',
            globalFiles: 'Bijzondere bestanden',
        },
        audit: {
            accessLog: 'Toegangslog',
        },
        account: {
            account: 'Account',
            changelog: 'Changelog'
        },
        sales: {
            sales: 'Verkoop',
            customers: 'Klanten',
            products: 'Artikelen',
            documents: 'Documenten',
            quotations: 'Dashboard',
        },
        files: {
            files: 'Bestanden',
        }
    },
    sales:{
        overview: {
            title: 'Verkoop',
        },
        customer: {
            title: 'Klanten'
        },
        document: {
            title: 'Documenten'
        },
    },
    unsavedChangesModal: {
        header: 'Onopgeslagen wijzigingen!',
        content: 'Onopgeslagen wijzigingen gevonden. Doorgaan en wijzigingen negeren?',
        discardChangesButton: 'Doorgaan & wijzigingen negeren',
    },
    duplicateModal: {
        header: 'Dupliceer',
        content: 'Dupliceer naar klant:',
    },
    quotation:{
        field: {
            id: {
                label: '#',
            },
            createdBy: {
                label: 'Aangemaakt door',
            },
            quotationContact: {
                label: 'Contactpersoon',
                subText: 'Gelijk aan zakenadres'
            },
            type: {
                label: 'Type',
                value: {
                    quotation: 'Offerte',
                    contract: 'Contract',
                },
            },
            rentbuy: {
                label: 'Huur/Koop', 
            }, 
            status: {
                label: 'Status',
            },
            totalPrice: {
                label: 'Totaal',
            },
            createdAt: {
                label: 'Datum',
            },
            rentalPeriod: {
                label: 'Formule naam',
            },
            language: {
                label: 'Taal',
                value: {
                    'nl-NL': 'NL',
                    'nl-BE': 'BE nl',
                    'fr-BE': 'BE fr',
                }
            },
            followUpDate: {
                label: 'Opvolgdatum',
            },
            updatedBy: {
                label: 'Gebruiker'
            },
            installationRequired: {
                label: 'Installatieopdracht is benodigd'
            },
            installationApproved: {
                label: 'Gelezen en akkoord installatieopdracht',
                subText: 'Zie gegevens bij tab \'productie\', \'installatie\' en \'levering\' voor meer informatie.'
            },
            termsAgreed: {
                label: 'Gelezen en akkoord algemene voorwaarden',
                subText: 'Zie tab \'bijlagen\' voor meer informatie.',
            },
            privacyPolicyAgreed: {
                label: 'Gelezen en akkoord privacy policy',
                subText: 'Zie tab \'bijlagen\' voor meer informatie.',
            },
            deliveryWeek:{
                label: 'Gewenste leverdatum',
                noTimeInterval: 'Geen tijden opgegeven',
            },
            deliveryUnloadingLocation: {
                label: 'Laad- en losplaats'
            },
            deliveryUnloadingRequired:{
                label: 'Geen laad- en losplaats gegevens'
            },
            deliveryUnloadingIncline:{
                at: 'op',
                cmFromEntrance: 'cm van ingang',
                label: 'losplaats'
            },
            deliveryStairs: {
                label: 'Trap',
                value: {
                    null: '-- Kies --',
                    'n/a': 'N.v.t.',
                    'upwards': 'Omhoog',
                    'downwards': 'Omlaag',
                },
            },
            deliveryStairsWidth: {
                label: 'Breedte'
            },
            deliveryStairsNumber: {
                label: 'cm en aantal treden'
            },
            deliveryDoorways: {
                label: 'Deuropeningen'
            },
            deliveryDoorwaysWidth: {
                label: 'Breedte'
            },
            deliveryDoorwaysHeight: {
                label: 'cm and height'
            },
            deliverySharpTurn:{
                label: 'Scherpe draai (doorgang meten)'
            },
            deliverySharpTurnWidth:{
                label: 'Breedte'
            },
            deliverySharpTurnHeight:{
                label: 'cm en hoogte'
            },
            deliveryThreshold: {
                label: 'Drempel hoger dan 10cm'
            },
            deliveryThresholdWidth: {
                label: 'Breedte'
            },
            deliveryThresholdHeight: {
                label: 'cm en hoogte'
            },
            deliveryChasis: {
                label: 'Plaatsing op onderstel type'
            },
            deliveryReturningEquipment:{
                label: 'Retour nemen apparatuur'
            },
            deliveryVerticalTransportProvided:{
                label: 'Klant zorgt voor verticaal transport'
            },
            deliveryComments: {
                label: 'Opmerkingen'
            },
            signature: {
                label: 'Handtekening',
            },
            purchaseOrderNumber:{
                label: 'Inkoopordernummer'
            },
            currentType: {
                label: 'Stroomsoort',
                value: {
                    null: '-- Kies --',
                    'n/a': 'N.v.t.',
                    '230': '230 V-1+N+A (=monofase)',
                    '380': '380 V-3+N+A',
                    '220': '220 V-3+N+A',
                },
            },
            electricityTypeProduction: {
                label: 'Elektra',
                value: {
                    null: '-- Kies --',
                    'n/a': 'N.v.t.',
                    'kW_standard': 'kW standaard',
                    'kW_optional': 'kW optioneel',
                },
            },
            deliveryAddress: {
                label: 'Plaatsingsadres',
                subText: 'Gelijk aan zakenadres',
            },
            invoiceAddress: {
                label: 'Factuuradres',
                subText: 'Gelijk aan zakenadres',
            },
            hinges: {
                label: 'Scharnieren',
                value: {
                    null: '-- Kies --',
                    'n/a': 'N.v.t.',
                    'left': 'Links',
                    'right': 'Rechts',
                },
            },
            profile: {
                label: 'Toe te voegen programma',
            },
            waterIn: {
                label: 'Wateraanvoer',
                subText: 'Beluchte koudwaterkraan 3/4” wartel, 2-5 bar',
            },
            waterOut: {
                label: 'Waterafvoer',
                subText: 'o PVC 50mm incl. sifon 300mm onder aansluitpunt apparaat',
            },
            waterSoftener: {
                label: 'Waterontharder c.q. -filter',
                subText: 'De klant blijft verantwoordelijk voor schade door kalk en chloride. Aanschaf van een waterontharder en/of -filter ontheft de klant niet van deze verantwoordelijkheid.',
            },
            waterSoftenerType: {
                label: 'Waterontharder',
                value: {
                    null: '-- Kies --',
                    'n/a': 'N.v.t.',
                    'existing': 'Reeds aanwezig',
                    'supplied': 'Levering Levens Middleby',
                    'own risk': 'Klant neemt geen aanbevolen waterontharder c.q. - filter af en is bekend met de (mogelijke) gevolgen hiervan',
                },
            },
            electricityInstallation: {
                label: 'Elektra',
            },
            electricityTypeInstallation: {
                label: 'Elektra',
                value: {
                    null: '-- Kies --',
                    'n/a': 'N.v.t.',
                    '380 wd 16': '380 V-3+N+A CEE WD 16',
                    '380 wd 32': '380 V-3+N+A CEE WD 32',
                    '380 wd 63': '380 V-3+N+A CEE WD 63',
                    '380 wd 125': '380 V-3+N+A CEE WD 125',
                },
            },
            electricitySeparateGroup: {
                subText: 'Afzekeren voor I=P/660, apart elektrische groep',
            },
            gasInA3: {
                label: 'Gasaanvoer',
                subText: 'Afvoerklassen A3, aansluitdruk 28-25 mBar',
            },
            gasSupplyType: {
                label: 'Gasaanvoer',
                value: {
                    null: '-- Kies --',
                    'n/a': 'N.v.t.',
                    '05 NEN': '0,5 volgens NEN 3258',
                    'W6pg': 'W6pg aardgas',
                    '12L': 'Toestelcategorie 12L',
                },
            },
            gasAndElectricity: {
                label: 'Gas en Elektra',
                subText: '230 V + aarde, 50/60 Hertz',
            },
            airOut: {
                label: 'Luchtafvoer',
                subText: 'o 200mm',
            },
            level: {
                label: 'Waterpas',
                subText: 'Vloer en muren',
            },
            space: {
                label: 'Ruimte',
                subText: 'Min, 30 cm aan rechterzijde t.b.v. service en ontkalking',
            },
            remarks: {
                label: 'Opmerkingen/overige producten',
            },
            otherSignees: {
                label: 'Overige vennoten / bestuurders',
                add: {
                    label: 'Overige vennoten / bestuurders toevoegen'
                }
            },
           paymentTerms: {
                label: 'Betalingscondities',
                value: {
                    null: '-- Kies --',
                    'direct debit': 'Betaling vooraf middels automatische incasso',
                    'before delivery': 'Betaling 100% voor levering',
                    '8 days after': 'Betaling 8 dagen na levering',
                    '10-50-35-5': 'Betaling 10% bij opdracht, 50% bij bestellen van de producten, 35% 1 week voor levering en 5% binnen 8 dagen na oplevering',
                    'within 30 days': 'Betaling binnen 30 dagen na factuurdatum',
                    'rental': 'Huurafspraak',
                    'custom': 'Met de hieronder ingevulde voorwaarden',
                },
            },
            paymentTermsDetails: {
                label: 'Betalingscondities voorwaarden',
            },
            addBtw: {
                label: 'BTW bedrag',
                subText: 'Voeg BTW percentage toe aan het volledige bedrag',
            },
            deleted: {
                label: 'Verwijderd',
            },
        },
        status: {
            draft: {
                label: 'Concept'
            },
            sent: {
                label: 'Verzonden'
            },
            draft_contract: {
                label: 'Concept'
            },
            sent_contract: {
                label: 'Verzonden'
            },
            approved:{
                label: 'Goedgekeurd'
            }
        },
        option: {
            attachments: {
                label: 'Bijlagen',
                add: {
                    label: 'Bijlagen toevoegen'
                },
            },
            approve:{
                label: 'Goedkeuren',
            },
            duplicate:{
                label: 'Dupliceer',
            },
            remove:{
                label: 'Verwijderen',
            },
            downloadQuote:{
                label: 'Offerte download',
            },
            downloadContract:{
                label: 'Contract download',
            },
            downloadPurchaseContract:{
                label: 'Koopovereenkomst download',
            },
            downloadRentalContract:{
                label: 'Huurovereenkomst download'
            },
            downloadInstallationReport:{
                label: 'Installatie Report download',
            },
            downloadProrentInstallationReport:{
                label: 'Prorent Installatie Report download',
            },
        },
        editModal: {
            title: 'Offerte',
            searchProduct: 'Zoek een product en voeg deze toe ...',
            sendQuotationButton: 'Offerte versturen',
            sendContractButton: 'Contract versturen',
            resendQuotationButton: 'Offerte opnieuw versturen',
            resendContractButton: 'Contract opnieuw versturen',
        },
        tab:{
           articles:{
             label: 'Artikelen',
           },
           details: {
              label: 'Details',
           },
           production: {
               label: 'Productie'
           },
           installation: {
               label: 'Installatie'
           },
           delivery: {
               label: 'Levering'
           },
           agreement: {
               label: 'Akkoord'
           },
           email: {
               label: 'E-mail',
           },
           attachments: {
               label: 'Bijlagen'
           },
        },
    },
    quotationProduct:{
        field: {
            rentDuration: {
                label: 'Looptijd',
                value: {
                    buy: 'Koop',
                    rent: 'Huur',
                    durationInMonths: '{{duration}} maanden',
                },
            },
            monthlyRent: {
                label: 'Maandhuur'
            },
            deposit: {
                label: 'Waarborg'
            },
        },
    },
    email: {
        field: {
            recipients: {
                label: 'Naar'
            },
            cc: {
                label: 'CC'
            },
            subject: {
                label: 'Onderwerp'
            },
            content: {
                label: 'Tekst'
            }
        },
    },
    document: {
        field: {
            name: {
                label: 'Naam'
            },
            sender: {
                label: 'Afzender'
            },
            category: {
                label: 'Categorie',
                value: {
                    'privacy policy': 'Privacybeleid',
                    'purchase agreement': 'Koopovereenkomst',
                    'purchase terms': 'Koopvoorwaarden',
                    'rental agreement': 'Huurovereenkomst',
                    'rental terms': 'Huurvoorwaarden',
                    'installation report': 'Installatierapport',
                    'other': 'Anders',
                    'quotation': 'Offerte',
                    'contract': 'Overeenkomst',
                },
            },
             language: {
                label: 'Taal',
                value: {
                    'all': 'Alle',
                    'nl-NL': 'NL',
                    'nl-BE': 'BE nl',
                    'fr-BE': 'BE fr',
                },
            },
            file: {
                label: 'File'
            },
        },
        overview: {
            addButton: 'Maak nieuw document'
        },
        create: {
            title: 'Document Toevoegen'
        },
        edit: {
            title: 'Document Bewerken'
        }
    },
    address: {
        field: {
            street: {
                label: 'Straat'
            },
            number: {
                label: 'Huisnummer'
            },
            city: {
                label: 'Plaats'
            },
            postcode: {
                label: 'Postcode'
            },
            fullAddress: {
                label: 'Adres'
            },
            addressLine: {
                label: 'Adres (straat en nummer)'
            }, 
            companyName: {
                label: 'Opmerking'
            }
        },
    },
    contactPerson: {
        field: {
            initial: {
                label: 'Initialen'
            },
            firstName: {
                label: 'Voornaam'
            },
            middleName: {
                label: 'Tussenvoegsel'
            },
            lastName: {
                label: 'Achternaam'
            },
            fullname: {
                label: 'Contactpersoon'
            },
            birthDate: {
                label: 'Geboortedatum'
            },
            nationality: {
                label: 'Nationaliteit '
            },
            function: {
                label: 'Functie'
            },
            passport: {
                label: 'Paspoortnummer',
                note: 'Verplicht als nationaliteit afwijkt van land van overeenkomst'
            },
            phone: {
                label: 'Telefoonnummer'
            },
            mobile: {
                label: 'Mobiele nummer',
            },
            email: {
                label: 'E-mailadres'
            },
            newsletter: {
                label: 'Nieuwsbrief'
            },
            title:{
                label: 'Aanhef',
                mr: {
                    label: 'Dhr.',
                },
                ms: {
                    label: 'Mevr.'
                }
            },
            privateAddress: 'Privé-adres',
            street: {
                label: 'Straat'
            },
            number: {
                label: 'Huisnummer'
            },
            city: {
                label: 'Plaats'
            },
            postcode: {
                label: 'Postcode'
            },
            fullAddress: {
                label: 'Adres'
            },
            addressLine: {
                label: 'Adres (straat en nummer)'
            }, 

        },
    },
    customer: {
        overview: {
            title: 'Klanten',
            addButton: 'Voeg klant toe',
            import: {
                csv: 'Importeer CSV'
            },
        },
        info: {
            title: 'Klantinformatie'
        },
        field: {
            relationNumber: {
                label: 'Relatienummer'
            },
            status: {
                label: 'Status'
            },
            followUpDate: {
                label: 'Opvolg datum',
            },
            establishedSince: {
                label: 'Gevestigd Sinds'
            },
            companyType: {
                label: 'Bedrijfsvorm'
            },
             mainActivity: {
                label: 'Hoofdactiviteit',
                value: {
                    'dealer': 'Dealer',
                    'retail': 'Retail',
                    'foodservice': 'Foodservice',
                    'fastservice': 'Fastservice',
                    'facility': 'Facilitair',
                    'leisure': 'Leisure',
                    'petrol': 'Petrol',
                    'bakery': 'Bakkerij',
                    'industry': 'Industrie',
                    'delivery': 'Bezorging',
                    'to go': 'To go',
                    'other': 'Overig',
                    'intercompany': 'InterCompany',
                    'unknown': 'Unknown',
                    'reseller': 'Wederverkoper',
                    'to be determined': 'Nader te bepalen'
                },
            },
            mainActivitySubcategory:{
                label: 'Subsegment',
                value: {
                    'supermarket': 'Supermarkt',
                    'produce': 'AGF',
                    'bakery': 'Bakkerij',
                    'butchery': 'Slagerij/traiteur',
                    'fish': 'Vis',
                    'hotel': 'Hotel',
                    'restaurant': 'Restaurant',
                    'eatery': 'Eetcafe',
                    'lunchroom': 'Lunchroom',
                    'pizza': 'Pizza',
                    'burger':  'Burger',
                    'cafeteria':  'Cafeteria',
                    'chicken': 'Kip',
                    'government': 'Overheid',
                    'catering': 'Catering',
                    'company restaurant': 'Bedrijfsrestaurant',
                    'care': 'Zorg',
                    'education': 'Onderwijs',
                    'holiday park': 'Vakantiepark/camping',
                    'theme park': 'Pretpark',
                    'party center': 'Partycentrum',
                    'sport': 'Sport',
                    'other': 'Overig',
                    'warehouse': 'Warenhuis',
                    'to be determined': 'Nader te bepalen',
                    'retail': 'Retail',
                    'coffe': 'Koffie',
                    'sandwich': 'Sandwich',
                    'petrol': 'Petrol',
                    'industry': 'Industrie',
                    'to go': 'To go',
                    'consultant': 'Consultant',
                    'intercompany': 'InterCompany',
                    'delivery': 'Delivery',
                    'wholesale': 'Groothandel',
                    'reseller': 'Wederverkoop',
                    'major kitchen dealer': 'Groot keukendealer',
                    'webshop': 'Webshop'
                },
            },
            initialContact: {
                label: 'Oorsprong',
                value: {
                    'advertisement': 'Advertentie',
                    'exchange': 'Beurs',
                    'website contactform': 'Contactformulier website',
                    'dealer': 'Dealer',
                    'demo day': 'Demodag',
                    'call': 'Inbel',
                    'cold purchase': 'Koude acquisitie',
                    'mailing': 'Mailing',
                    'network': 'Netwerk',
                    'service': 'Service',
                    'social media': 'Social media',
                    'telemarketing': 'Telemarketing',
                    'online lead': 'Online lead',
                    'other': 'Overig',
                    'event': 'Event',
                    'import': 'Import',
                    'webshop': 'Webshop',
                    'reseller': 'Reseller',
                    'cross selling': 'Cross selling',
                    'fair': 'Fair',
                    'coupon': 'Coupon',
                    'headquarters': 'Headquarters',
                    'letter': 'Letter',
                    'email': 'Email',
                    'innovation days 06-22': 'Innovation days 06-22'
                },
            },
            name: {
                label: 'Bedrijfsnaam'
            },
            bicCode: {
                label: 'BIC code'
            },
            kvkCode: {
                label: 'KvK/btw-nummer'
            },
            iban: {
                label: 'IBAN'
            },
            language: {
                label: 'Taal'
            },
            contactPerson: {
                label: 'Contactpersoon'
            },
            address: {
                label: 'Adres'
            },
            lastUpdatedBy: {
                label: 'Laatst bijgewerkt door '
            },
            importModal:{
                importedStatusLabel: '{{number}} bestanden zijn geïmporteerd van {{files}}.',
                updatedStatusLabel: '{{number}} bestanden zijn geïmporteerd. U probeerde {{updatedNumber}} bestaande klanten te overschrijven.'
            }
        },
    },
    product: {
        overview: {
            title: 'Artikelen',
            addButton: 'Maak artikel aan',
            export: {
                csv: 'Exporteer naar CSV'
            },
            import: {
                csv: 'Importeer CSV'
            },
        },
        create: {
            title: 'Maak artikel aan'
        },
        edit: {
            title: 'Bewerk artikel'
        },
        field: {
            id: {
                label: 'ID'
            },
            articleCode: {
                label: 'Artikelcode'
            },
            brand: {
                label: 'Merk'
            },
            name: {
                label: 'Artikel'
            },
            description: {
                label: 'Beschrijving'
            },
            buyPrice: {
                label: 'Prijs (excl.)'
            },
            rentPrice: {
                label: 'Huurprijs (excl.)'
            },
            deposit: {
                label: 'Borg (excl.)'
            },
            needsSoftener: {
                label: "Waterontharder nodig",
            }
        },
    },
    user: {
        overview: {
            title: 'Gebruikers',
            addButton: 'Nieuwe Gebruiker',
        },
        create: {
            title: 'Gebruiker Aanmaken',
        },
        edit: {
            title: 'Gebruiker Aanpassen',
        },
        field: {
            fullName: { label: 'Naam' },
            firstName: { label: 'Voornaam' },
            lastName: { label: 'Achternaam' },
            phone: {label: 'Telefoon Nummer'}, 
            email: { label: 'E-mail' },
            language: {label: 'Taal'},
            password: { label: 'Wachtwoord' },
            dateJoined: { label: 'Toegevoegd Op' },
            groups: {
                label: 'Groepen',
                value: {
                    superuser: 'Supergebruiker',
                    admin: 'Administrator',
                    sales_agent: 'Verkoopagent',
                    manager: 'Manager',
                },
                empty: 'Geen groepen toegekend',
            },
            isActive: {
                label: 'Users',
                options: {
                    all: 'All',
                    yes: 'Active',
                    no: 'Not Active',
                }
            },
        },
        login: {
            title: 'Inloggen',
            forgotPasswordLink: 'Wachtwoord vergeten?',
            errors: {
                invalidCredentials: 'E-mail of wachtwoord is niet correct',
                unknown: 'Onbekende fout, status code: {{status}}', },
            loginButton: 'Inloggen',
        },
        passwordForgot: {
            title: 'Wachtwoord Vergeten',
            requestButton: 'Verstuur Email',
            requestedEmailText:
                'Er is een email met een wachtwoord reset code verzonden naar uw email adres.',
            loggedInError: 'Je bent ingelogd. Je kunt alleen wachtwoord vergeten aanvragen als je uitgelogd bent.',
        },
        resetPassword: {
            title: 'Wachtwoord Reset',
            resetButton: 'Reset',
            saveSuccess: 'Wachtwoord is succesvol gereset',
            loggedInError: 'Je bent ingelogd. Je kunt alleen wachtwoord resetten als je uitgelogd bent.',
        },
        changePassword: {
            title: 'Wachtwoord Aanpassen',
            field: {
                passwordOld: {
                    label: 'Huidig wachtwoord',
                },
                passwordNew: {
                    label: 'Nieuw wachtwoord',
                },
            },
            saveButton: 'Opslaan',
        },
        account: {
            title: 'Profiel',
            logoutButton: 'Uitloggen',
            info: {
                title: 'Gegevens',
            },
            password: {
                title: 'Wachtwoord Veranderen',
            },
            logout: {
                title: 'Uitloggen',
                confirm: 'Weet u zeker dat u wilt uitloggen?'
            },
        },
    },
    globalValue: {
        edit: {
            title: 'Waarde wijzigen van algemene instelling',
        },
        overview: {
            title: 'Algemene instellingen',
        },
        field: {
            key: {
                label: 'Naam instelling',
            },
            value: {
                label: 'Waarde',
            },
            writable: {
                label: 'Schrijfbaar?',
            },
            readable: {
                label: 'Leesbaar?',
            },
        },
    },
    globalFile: {
        edit: {
            title: 'Bijzonder bestand bewerken',
        },
        overview: {
            title: 'Bijzondere bestanden',
        },
        field: {
            key: {
                label: 'Naam voor bestand',
            },
            value: {
                label: 'Bestand',
            },
            writable: {
                label: 'Schrijfbaar?',
            },
            readable: {
                label: 'Leesbaar?',
            },
        },
    },
    accessLogEntry: {
        overview: {
            title: 'Auditlog',
        },
        field: {
            originalUser: {
                label: 'Oorspronkelijke gebruiker',
            },
            masqueradingAsUser: {
                label: 'Gemaskeerd als gebruiker',
            },
            ipAddress: {
                label: 'IP-adres',
            },
            method: {
                label: 'HTTP-methode',
            },
            endpoint: {
                label: 'Endpoint',
            },
            statusCode: {
                label: 'Statuscode',
            },
            additionalInfo: {
                label: 'Extra informatie',
            },
            requestTime: {
                label: 'Tijdstip van toegang',
            },
        },
    },
    myFilter: {
        custom: 'Nieuw',
        action: {
            unsetDefault: 'Gebruik niet als standaard',
            setDefault: 'Gebruik als standaard',
            setCurrent: 'Sla huidige filters op',
            delete: 'Verwijderen',
        },
    },
    tooltips: {
        masquerade: 'Voordoen als',
        edit: 'Aanpassen',
        delete: 'Verwijderen',
        view: 'Bekijk',
        restore:'Herstel',
    },
    form: {
        repeat: 'Herhaal',
        fileType: {
            pdf: 'PDF',
            image: 'IMG',
            xls: 'XLS',
            any: 'Anders',
            none: 'Nog geen bestand geüpload',
        },
        multiPick: {
            searchPlaceholder: 'Selecteer…',
            selectedText: '$1 uit $2 geselecteerd',
            noneSelectedText: 'Geen geselecteerd',
            selectAllButton: 'Alle',
            selectNoneButton: 'Geen',
        },
        time: {
            from: 'from',
            to: 'to',
            hour: 'hour',
        },
        bool: {
            true: 'Ja',
            false: 'Nee'
        },
        notifications: {
            saveSuccess: 'Succesvol opgeslagen',
            deleteSuccess: 'Succesvol verwijderd',
            saveError: 'Fout met opslaan ({{status}})',
            saveValError: 'Niet alle informatie is correct ingevuld',
            saveAuthError: 'Niet geauthorizeerd',
            newAppVersion:
                'Nieuwe versie beschikbaar, klik om de pagina te verversen',
        },
        required: '* Verplicht',
        cancelButton: 'Annuleren',
        saveButton: 'Opslaan',
        submitButton: 'Verstuur',
        applyButton: 'Dupliceren',
        addButton: 'Toevoegen',
        deleteConfirmation: 'Weet u zeker dat u dit wilt verwijderen?',
        restoreConfirmation: 'Weet u zeker dat u dit wilt herstellen?',
        startDate: 'Start Datum',
        endDate: 'Eind Datum',
        pdfError: 'Dit bestand lijkt geen geldig PDF document te zijn.',
        pdfLoading: 'Het bestand wordt momenteel geladen...',
    },
    crash: {
        heading: 'Applicatie gecrasht',
        subHeading1: 'Onze excuses, maar de applicatie is gecrasht.',
        subHeading2: 'Ons team is op de hoogte gesteld.',
        reload: 'Herlaad pagina'
    },
    daycy: {
        week: {
            label: 'Wk',
            number: '{{weekNumber}}',
        },
        weekDay: {
            monday: 'Ma',
            tuesday: 'Di',
            wednesday: 'Wo',
            thursday: 'Do',
            friday: 'Vr',
            saturday: 'Za',
            sunday: 'Zo',
        },
        month: {
            january: 'Januari',
            february: 'Februari',
            march: 'Maart',
            april: 'April',
            may: 'Mei',
            june: 'Juni',
            july: 'Juli',
            august: 'Augustus',
            september: 'September',
            october: 'Oktober',
            november: 'November',
            december: 'December',
        },
    },
    locale: {
        NL_NL: {
            label: 'NL',
        },
        EN_GB: {
            label: 'EN',
        },
        BE_FR: {
            label: 'BE FR',
        },
        BE_NL:{
            label: 'BE NL'
        },
    },
    pageNotFoundMessage: 'Pagina niet gevonden',
    brandingMessage: 'Powered by Code Yellow',
};
