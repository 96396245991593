import { tint } from 'polished';

const primaryColor = '#0A2A57'; // {copy-paste-primary-color}
const tintedPrimaryColor = tint(0.15, primaryColor);
const buttonColor = '#48b4ca';
const dangerColor = '#dc0818';
const altDangerColor = '#C33F38';
const warningColor = '#ffc107';
const backgroundColor = '#fff';

export const theme = {
    primaryColor,
    tintedPrimaryColor,
    buttonColor,
    dangerColor,
    altDangerColor,
    warningColor,
    backgroundColor
};
